// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/Company_All',//select下拉数据
	CompanyDevSenList: '/v3/Data/Company_Device_Sensor_List',//左侧菜单列表
}


//左侧菜单列表
export function CompanyDevSenList(data) {
	return axios({
		url: ClassApi.CompanyDevSenList,
		method: 'post',
		data:data
	})
}
//select下拉数据
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}